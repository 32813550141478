import * as React from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { gsap } from "gsap";
import Icon from "@components/Icon";
import styles from "./layout.module.scss";

// const VirtualScrollOnlyOnClient = dynamic(
//   () => import("@utils/virtualScroll"),
//   { ssr: false }
// );

const Layout: React.FC = (props) => {
  let bgRef = React.useRef<HTMLDivElement | null>(null);
  let allRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  React.useEffect(() => {
    let currentAllRefs = allRefs.current as gsap.TweenTarget;
    let currentBgRef = bgRef.current as gsap.TweenTarget;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline();

    tl1
      .staggerTo(
        currentAllRefs,
        1.8,
        { autoAlpha: 0, duration: 1.8, scale: 0 },
        0.05
      )
      .to(
        currentBgRef,
        {
          duration: 1.2,
          clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
          autoAlpha: 0,
          ease: "expo.out",
        },
        "-=0.3"
      );

    return () => {};
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
      </Head>

      <main data-scroll-container>
        <div className={styles["layout"]} ref={bgRef}>
          <div
            className={styles["layout__hidden"]}
            ref={(element) => {
              allRefs.current[0] = element;
            }}
          >
            <div>
              <Icon icon="smallLogo" />
            </div>
            <div>
              <Icon icon="fullLogoWhite" />
            </div>
          </div>
        </div>
        {props.children}
      </main>
      {/* <VirtualScrollOnlyOnClient /> */}
    </>
  );
};

export default Layout;
