import styles from "./home.module.scss";
import * as React from "react";
import Header from "@components/Header";
import Hero from "@components/Hero";
import Pictures from "@components/Pictures";
import Sections from "@components/Sections";
import Photo from "@components/Photo";
import Footer from "@components/Footer";
import Website from "@components/Website";

type Props = {};

const Home = (props: Props) => {
  React.useEffect(() => {
    // GSDevTools.create();
  }, []);

  return (
    <div className={styles["home"]} data-scroll-section>
      <Header />
      <Hero />
      <Pictures />
      <Sections />
      <Photo />
      <Website />
      <Footer />
    </div>
  );
};

export default Home;
