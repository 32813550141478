import * as React from "react";
import Home from "src/pages/Home";
import Layout from "@components/Layout";

const Index = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default Index;
