import Link from "next/link";
import { useRouter } from "next/router";
import styles from "./button.module.scss";

type Props = {
  type?: "button" | "submit" | "reset";
  theme?: "primary" | "secondary" | "tertiary" | "quaternary" | "quinary";
  to?: string;
  href?: string;
  newPage?: boolean;
  onClick?: (args?: any) => void;
};

const Button: React.FC<Props> = (props) => {
  const router = useRouter();
  return (
    <>
      {!!props.to ? (
        <Link href={props?.to ?? ""} passHref={!!props.to}>
          <button
            onClick={props.onClick}
            type={props?.type ?? "button"}
            className={(() => {
              switch (props.theme) {
                case "secondary":
                  return styles["button__secondary"];
                  break;
                case "tertiary":
                  return styles["button__tertiary"];
                  break;
                default:
                  return styles["button__primary"];
              }
            })()}
          >
            <span>{props.children}</span>
          </button>
        </Link>
      ) : !!props.href ? (
        <a
          href={props?.href ?? ""}
          target={props.newPage ? "_blank" : ""}
          rel="noreferrer noopener"
        >
          <button
            onClick={props.onClick}
            type={props?.type ?? "button"}
            className={(() => {
              switch (props.theme) {
                case "secondary":
                  return styles["button__secondary"];
                  break;
                case "tertiary":
                  return styles["button__tertiary"];
                  break;
                default:
                  return styles["button__primary"];
              }
            })()}
          >
            <span>{props.children}</span>
          </button>
        </a>
      ) : (
        <button
          onClick={props.onClick}
          type={props?.type ?? "button"}
          className={(() => {
            switch (props.theme) {
              case "secondary":
                return styles["button__secondary"];
                break;
              case "tertiary":
                return styles["button__tertiary"];
                break;
              default:
                return styles["button__primary"];
            }
          })()}
        >
          <span>{props.children}</span>
        </button>
      )}
    </>
  );
};

export default Button;
