import * as React from "react";
import styles from "./footer.module.scss";
import { SplitText } from "@utils/gsap/SplitText";
import { gsap } from "gsap";
import Link from "next/link";
import Button from "@components/Button";
import Icon from "@components/Icon";

type Props = {};

const Footer: React.FC<Props> = (props) => {
  let sectionRef = React.useRef<HTMLElement | null>(null);
  let topRef = React.useRef<HTMLDivElement | null>(null);
  let picsRef = React.useRef<HTMLDivElement | null>(null);
  let lineRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTopRef = topRef.current as gsap.TweenTarget;
    let currentSectionRef = sectionRef.current;
    let currentPicsSectionRef = picsRef.current;
    let currentLineRefs = lineRefs.current as gsap.TweenTarget;

    let mySplitText = new SplitText(currentTopRef, { type: "words,chars" });

    let characters = mySplitText.chars;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: currentSectionRef,
        start: "top 70%",
        // scrub: true,
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: currentPicsSectionRef,
        start: "top 80%",
        // scrub: true,
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    tl1.from(
      characters,
      {
        duration: 0.8,
        opacity: 0,
        scale: 0.8,
        y: 100,
        transformOrigin: "0% 50% -50",
        ease: "Power4.out",
        stagger: 0.02,
      },
      "-=0.2"
    );

    tl2.staggerFrom(
      currentLineRefs,
      1,
      {
        autoAlpha: 0,
        y: 60,
        delay: 0.7,
        ease: "expo.out",
      },
      0.1
    );

    return () => {};
  }, []);

  return (
    <section className={styles["footer"]} ref={sectionRef}>
      <div className={styles["footer__container"]}>
        <div className={styles["footer__hidden"]}>
          <div className={styles["footer__text"]} ref={topRef}>
            Inawo puts all you need for a great wedding in one place
          </div>
        </div>
      </div>

      <div className={styles["footer__relative"]} ref={picsRef}>
        <div className={styles["footer__container"]}>
          <div className={styles["footer__middle"]}>
            <div className={styles["footer__middle__stack"]}>
              <div className={styles["footer__middle__full-logo"]}>
                <div
                  ref={(element) => {
                    lineRefs.current[0] = element;
                  }}
                >
                  <Icon icon="fullLogoWhite" />
                </div>
              </div>
              <div className={styles["footer__middle__hidden"]}>
                <div
                  className={styles["footer__middle__text"]}
                  ref={(element) => {
                    lineRefs.current[1] = element;
                  }}
                >
                  An all-in-one platform that gives you the
                </div>
              </div>
              <div className={styles["footer__middle__hidden"]}>
                <div
                  className={styles["footer__middle__text"]}
                  ref={(element) => {
                    lineRefs.current[2] = element;
                  }}
                >
                  tools to create a wedding experience of
                </div>
              </div>
              <div className={styles["footer__middle__hidden"]}>
                <div
                  className={styles["footer__middle__text"]}
                  ref={(element) => {
                    lineRefs.current[3] = element;
                  }}
                >
                  your choice.
                </div>
              </div>
            </div>
            <div
              className={styles["footer__button"]}
              ref={(element) => {
                lineRefs.current[4] = element;
              }}
            >
              <Button
                theme="secondary"
                href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}register`}
                newPage
              >
                Start A Wedding
              </Button>
            </div>
          </div>

          <div className={styles["footer__bottom"]}>
            <div
              className={styles["footer__bottom__left"]}
              ref={(element) => {
                lineRefs.current[5] = element;
              }}
            >
              <div>
                <Icon icon="smallLogo" />
              </div>
              <Link href="/" passHref>
                <a>Terms & Conditions</a>
              </Link>
              <Link href="/" passHref>
                <a>Privacy</a>
              </Link>
            </div>

            <div
              className={styles["footer__bottom__right"]}
              ref={(element) => {
                lineRefs.current[6] = element;
              }}
            >
              <a
                href="https://facebook.com/Inawocom-100140535884970"
                target="_blank"
                rel="noreferrer noopener"
              >
                Facebook
              </a>
              <a
                href="https://instagram.com/inawoweddings?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer noopener"
              >
                Instagram
              </a>
              <a
                href="https://twitter.com/inawoweddings"
                target="_blank"
                rel="noreferrer noopener"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
