import * as React from "react";
import Icon from "@components/Icon";
import Button from "@components/Button";
import styles from "./header.module.scss";
import Link from "next/link";
import { gsap } from "gsap";

type Props = {};

const Header: React.FC<Props> = (props) => {
  let allRefs = React.useRef<Array<HTMLDivElement | null>>([]);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  React.useEffect(() => {
    let currentAllRefs = allRefs.current as gsap.TweenTarget;

    gsap.defaults({ ease: "expo.out" });

    let tl1 = gsap.timeline();

    tl1.staggerFrom(
      currentAllRefs,
      1,
      { delay: 1.8, autoAlpha: 0, y: 50 },
      0.4
    );

    return () => {};
  }, []);

  return (
    <section className={styles["header"]}>
      <div className={styles["header__container"]}>
        <div className={styles["header__hidden"]}>
          <div
            className={styles["header__logo"]}
            ref={(element) => {
              allRefs.current[0] = element;
            }}
          >
            <Link href="/">
              <a>
                <Icon icon="fullLogoGreen" />
              </a>
            </Link>
          </div>
        </div>

        <div className={styles["header__hidden"]}>
          <div
            className={styles["header__group"]}
            ref={(element) => {
              allRefs.current[1] = element;
            }}
          >
            <a
              href={process.env.NEXT_PUBLIC_DASHBOARD_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className={styles["header__group__login"]}>LOG IN</button>
            </a>
            <Button
              href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}register`}
              newPage
            >
              START A WEDDING
            </Button>
          </div>
        </div>

        <div className={styles["header__hidden__mobile"]}>
          <div
            className={styles["header__group__mobile"]}
            ref={(element) => {
              allRefs.current[2] = element;
            }}
            // onClick={() => setOpenMenu((prev) => !prev)}
          >
            {/* <div
            className={`${styles["header__group__mobile__line"]} ${
              openMenu ? styles["header__group__mobile__line__open"] : ""
            }`}
          /> */}
            <a
              href={process.env.NEXT_PUBLIC_DASHBOARD_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className={styles["header__group__login"]}>LOG IN</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
