import * as React from "react";
import styles from "./website.module.scss";
import { SplitText } from "@utils/gsap/SplitText";
import { gsap } from "gsap";
import Image from "next/image";
import SiteImage from "../../../public/assets/images/guest-site.png";
import Button from "@components/Button";

type Props = {};

const Website: React.FC<Props> = (props) => {
  let textRef = React.useRef<HTMLParagraphElement | null>(null);
  let sectionRef = React.useRef<HTMLElement | null>(null);
  let bottomRef = React.useRef<HTMLDivElement | null>(null);
  let imageAndButtonRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTextRef = textRef.current as gsap.TweenTarget;
    let currentButtonRef = bottomRef.current as gsap.TweenTarget;
    let currentSectionRef = sectionRef.current;
    let currentImageAndButtonRefs =
      imageAndButtonRefs.current as gsap.TweenTarget;

    let mySplitText = new SplitText(currentButtonRef, { type: "words,chars" });

    let anotherSplitText = new SplitText(currentTextRef, {
      type: "words,chars",
    });

    let characters = mySplitText.chars;

    let anotherCharacters = anotherSplitText.chars;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: currentSectionRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    tl1
      .from(
        characters,
        {
          duration: 0.5,
          autoAlpha: 0,
          y: 100,
          ease: "Power4.out",
          stagger: 0.02,
        },
        "-=0.2"
      )
      .from(anotherCharacters, {
        duration: 0.1,
        autoAlpha: 0,
        y: 40,
        stagger: 0.01,
      })
      .staggerFrom(currentImageAndButtonRefs, 1.3, {
        duration: 1.3,
        y: 40,
        autoAlpha: 0,
        ease: "expo.out",
        stagger: 0.4,
      });

    return () => {};
  }, []);

  return (
    <section className={styles["website"]} ref={sectionRef}>
      <div className={styles["website__container"]}>
        <div className={styles["website__hidden"]}>
          <div className={styles["website__text"]} ref={bottomRef}>
            A wedding website that keeps your guests in the loop
          </div>
        </div>

        <p className={styles["website__text__tiny"]} ref={textRef}>
          Keep everyone in the loop and engaged with your site. Get them excited
          with your beautiful photos and love story.
        </p>

        <div
          className={styles["website__image"]}
          ref={(element) => {
            imageAndButtonRefs.current[0] = element;
          }}
        >
          <Image src={SiteImage} alt="inawo guest site" placeholder="blur" />
        </div>

        <div
          className={styles["website__button"]}
          ref={(element) => {
            imageAndButtonRefs.current[1] = element;
          }}
        >
          <Button
            theme="secondary"
            href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}register`}
            newPage
          >
            Start A Wedding
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Website;
